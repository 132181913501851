<template>
	<header
		v-if="!isWebview"
		class="header"
		:class="{ 'header--black': showBlackHeader }"
	>
		<div class="header__container">
			<CaLink
				icon="menu"
				aria-label="menu"
				color="white"
				no-padding
				@click="openSidebar"
			/>

			<LogoPetlove class="header__image" />

			<div class="flex align-items-center">
				<CaLink
					v-if="showSearch"
					icon="search"
					aria-label="buscar"
					no-padding
					@click="showSearchModal = true"
				/>
				<WsHeaderCartLink v-if="showCart" />
			</div>
		</div>

		<!-- Menu sidebar -->
		<Transition name="slide">
      <KeepAlive>
        <LazyWsHeaderMobileMenu
          v-show="showSidebar"
          @sidebar-close="showSidebar = false"
        />
      </KeepAlive>
    </Transition>
	</header>
</template>

<script setup>
import LogoPetlove from '../../Draws/logoPetloveMobile.vue';
import { useUiStore } from '@petlove/lion/store/ui';

defineProps({
	isLogged: { type: Boolean, default: false },
	user: { type: Object, default: () => ({}) },
	hideSidenav: { type: Boolean, default: false },
	showCart: { type: Boolean, default: true },
	showSearch: { type: Boolean, default: false },
	showBlackHeader: { type: Boolean, default: false }
});

const uiStore = useUiStore();
const { webView: isWebview } = storeToRefs(uiStore);
const showSidebar = ref(false);
const showSearchModal = ref(false);

function openSidebar() {
	showSidebar.value = true;
}

preloadComponents('WsHeaderMobileMenu');
</script>

<style lang="scss" scoped>
.header {
  background-color: color(primary);
  box-shadow: 5px solid color(primary);
  width: 100%;
  z-index: 3;
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  top: 0;

  &--black {
    background-color: $color-black;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: space(4) space(5);
    height: 56px;
  }

  &__image {
    height: 2.8rem;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.slide-enter-from {
  transform: translateX(-100%);
}

.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>
