<template>
  <footer
    v-if="!webView"
    ref="footerMobile"
    class="footer full-width text-center bg-white"
  >
    <!-- social media and apps section -->
    <section class="footer__app mb-8">
      <!-- petlove information -->
      <div>
        <p class="font-text-l color-primary font-bold mb-3">
          Baixe nosso aplicativo
        </p>
        <a
          href="https://petlove.onelink.me/419198568/86e9560f"
          title="Baixar na App Store"
        >
          <img
            src="https://www.petlove.com.br/static/uploads/banner_image/image/17754/apple_stroke.png"
            alt="Apple store"
            loading="lazy"
            height="42"
            width="143"
          >
        </a>
        <a
          href="https://petlove.onelink.me/419198568/86e9560f"
          title="Baixar na Google Play"
        >
          <img
            src="https://www.petlove.com.br/static/uploads/banner_image/image/17755/google_stroke.png"
            alt="Play store"
            loading="lazy"
            height="42"
            width="143"
          >
        </a>
      </div>
    </section>

    <!-- payment section -->
    <section class="footer__payment mb-8">
      <p class="font-text-l color-primary font-bold mb-3">
        Formas de pagamento
      </p>
      <img
        class="footer__payment__image"
        src="https://www.petlove.com.br/static/uploads/images/payment/methods.png"
        alt="Boleto, cartões de crédito e pix"
        loading="lazy"
        height="24"
        width="355"
      >
    </section>

    <section>
      <CaCardAccordion
        v-for="(footerLink, footerIndex) in links"
        :key="footerIndex"
      >
        <h3 class="color-primary">{{ footerLink.title }}</h3>
        <template #hidden>
          <div class="flex flex-column text-left">
            <a
              v-for="(link, linkIndex) in footerLink.links"
              :key="linkIndex"
              :href="link.url"
              :title="link.title"
              target="_blank"
              rel="noopener"
              class="footer__links"
              >{{ link.title }}</a
            >
          </div>
        </template>
      </CaCardAccordion>
    </section>

    <!-- sac section -->
    <section class="footer__sac mb-7">
      <CaLink
        href="https://atendimento.petlove.com.br"
        target="_blank"
        rel="noopener"
      >
        Central de Atendimento
      </CaLink>
    </section>

    <!-- copyright section -->
    <section class="footer__copyright font-body-xs">
      <!-- petlove information -->
      <p>
        Petsupermarket Comércio de Produtos para Animais S.A<br >
        CNPJ: 10.864.846/0001-23<br >
        Av. das Nações Unidas 12901 - 11º andar, Brooklin Paulista, São Paulo -
        SP, 04578-910<br >
        Copyright 2020 © Todos os direitos reservados à Petlove
      </p>
    </section>

    <WsZipcodeModal
      v-if="showModal"
      :show-modal="showModal"
      @handler-modal="handleCaDialog"
    />

    <CaSnackbar
      v-if="showZipcodeHeaderInfo"
      :show="showZipcodeHeaderInfo"
      :duration="15000"
      type="info"
      message="Identificamos seu CEP aproximado. Clique para alterá-lo"
      btn-label="Alterar"
      @click="handleCaDialog"
    />
  </footer>
</template>

<script>
import links from '@petlove/lion/libs/_mocks_/footer-links.js';
import { useShipmentStore } from '@petlove/lion/store/shipment';
import { mapActions, mapState } from 'pinia';

export default {
  props: {
    store: {
      type: Object,
      default: () => {},
      required: false,
    },
    webView: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const { getDYChoose } = useDynamicYield();

    return {
      getDYChoose,
    };
  },
  data() {
    return {
      links,
      showZipcodeHeaderInfo: false,
    };
  },
  computed: {
    ...mapState(useShipmentStore, ['selectedZipcode', 'showModal']),
  },
  selectedZipcode() {
    this.handleAutoCompleteDialog();
  },
  async mounted() {
    if (!this.selectedZipcode?.length) await this.fetchDYAutocompleteAB();

    this.handleAutoCompleteDialog();
  },
  methods: {
    ...mapActions(useShipmentStore, [
      'setIsAutocompleteZipcode',
      'setShowAutocompleteInfo',
      'fetchIpLocation',
      'isAutocompleteInfo',
      'isAutocompleteZipcode',
      'setShowModal'
    ]),
    handleCaDialog() {
      this.showZipcodeHeaderInfo = false;
      this.setShowModal(!this.showModal);
    },
    handleAutoCompleteDialog() {
      if(this.isAutocompleteZipcode() === 'true' && this.isAutocompleteInfo() === 'true') {
        this.showZipcodeHeaderInfo = true;
        setTimeout(() => {
          this.closeAutocompleteDialog();
        }, 15000);
      }
    },
    async fetchDYAutocompleteAB() {
      const payload = {
        experiment: 'DELIVERY_AUTOCOMPLETE',
        pageType: 'OTHER'
      };
      await this.fetchIpLocation()
      try {
        const data = await this.getDYChoose(payload);
        const showAutocomplete =
          data?.choices?.[0]?.variations?.[0]?.payload?.data?.autocomplete ||
          false;
        if (showAutocomplete) {
          await this.fetchIpLocation();
          
          if (window.clarity) {
            window.clarity(
              'set',
              'Testes A/B - Delivery',
              '[Delivery][A/B] - Autocomplete'
            );
          }
        }
      } catch (error) {
        this.$nuxt?.$honeybadger?.notify(error, {
          component: 'SellerInfo',
          action: 'fetchSellerInfoVariant',
          context: { payload }
        });
      }
    },
    closeAutocompleteDialog() {
      this.showZipcodeHeaderInfo = false;
      this.setShowAutocompleteInfo(false)
    },
  }
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid color(neutral, light);
  padding: 3rem 1rem 1.6rem;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;

  &__app {
    &-text {
      word-break: break-word;
    }
  }

  &__payment {
    &-svg {
      height: 2.4rem;
      width: 100%;
    }
    &__image {
      width: 100%;
      aspect-ratio: 4 / 3;
      object-fit: contain;
    }
  }

  &__links {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__copyright {
    line-height: 1.8rem;
    color: color(neutral, medium);
    &-partnership {
      border-top: 1px solid color(neutral, light);
      padding-top: 2rem;
    }
  }
}
</style>
